//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

body.lead{
	font-size: .875rem;
	font-weight: 400;
}

h4 a.toggle{
	float: right;
	font-size: .75rem;
	font-weight: normal;
}

.hidden_info{
	width: 150px;
	height: 20px;
	background: $gray-200;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}

#staging{
	background: $primary;
	color: white;
	text-align: center;
}

.crown{
	position: relative;

	&::before{
		content: '';
		display: block;
		width: 16px; height: 16px;
		position: absolute;
		left: -5px; top: -5px;
		transform: rotate(-25deg);
		background: url(../images/crown.svg) no-repeat;
		background-size: contain;
	}

	&.media::before{ width: 20px; height: 20px }
}

.icon-crown{
	display: inline-block;
	width: 16px; height: 19px;
	margin-left: 10px;
	vertical-align: middle;
	background: url(../images/crown.svg) no-repeat;
	background-size: contain;
}

.use_fineo_content {
    label {
        display: inline-block;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        padding: .45rem .9rem;
        font-size: .875rem;
        line-height: 1.5;
        border-radius: .15rem;
        cursor: pointer;
        &:nth-child(1) {
            color: #fff;
            background-color: #e61946;
            border-color: #e61946;
        }
        &:nth-child(2) {
            color: #fff;
            background-color: #3c4d5d;
            border-color: #3c4d5d;
        }
        input[type='radio'] {
            opacity: 0;
            position: fixed;
            width: 0;
        }
    }
}

#assign-reseller-modal {
    .table-resellers-list {
        height: 50vh;
        width: 100%;
        overflow-y: auto;
    }
}

.tooltip {
    pointer-events: none;
}
